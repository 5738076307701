import { Icon } from '@/client'
import { cn } from '@/lib/utils'
import {
	forwardRef,
	HTMLAttributes,
	ReactNode,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
/**
 * Calcula la distancia entre dos puntos en un plano 2D.
 * @param {number} x1 - Coordenada X del primer punto.
 * @param {number} y1 - Coordenada Y del primer punto.
 * @param {number} x2 - Coordenada X del segundo punto.
 * @param {number} y2 - Coordenada Y del segundo punto.
 * @returns {number} - La distancia euclidiana entre los dos puntos.
 */
const calculateDistance = (
	x1: number,
	y1: number,
	x2: number,
	y2: number,
): number => {
	return Math.hypot(x2 - x1, y2 - y1)
}

/**
 * Determina la esquina más cercana a la posición actual del componente draggable.
 * @param {number} x - Coordenada X actual del componente draggable.
 * @param {number} y - Coordenada Y actual del componente draggable.
 * @param {number} width - Ancho del viewport.
 * @param {number} height - Alto del viewport.
 * @param {number} elementWidth - Ancho del elemento draggable.
 * @param {number} elementHeight - Alto del elemento draggable.
 * @returns {{x: number, y: number}} - La esquina más cercana en forma de coordenadas {x, y}.
 */
const getNearestCorner = (
	x: number,
	y: number,
	width: number,
	height: number,
	elementWidth: number,
	elementHeight: number,
): { x: number; y: number } => {
	// Definimos las coordenadas de las cuatro esquinas
	const corners = [
		{ x: 0, y: 0 }, // Esquina superior izquierda
		{ x: width - elementWidth, y: 0 }, // Esquina superior derecha
		{ x: 0, y: height - elementHeight }, // Esquina inferior izquierda
		{ x: width - elementWidth, y: height - elementHeight }, // Esquina inferior derecha
	]

	// Calculamos la distancia a cada esquina y retornamos la más cercana
	const nearestCorner = corners.reduce((prev, curr) => {
		const prevDistance = calculateDistance(x, y, prev.x, prev.y)
		const currDistance = calculateDistance(x, y, curr.x, curr.y)
		return currDistance < prevDistance ? curr : prev
	})

	return nearestCorner
}

/**
 * Componente draggable que se ajusta a la esquina más cercana al soltarlo.
 * @component
 */
function SnappingDraggable({
	parentRef,
	children,
	initialPosition,
}: {
	children: ReactNode
	parentRef?: React.RefObject<HTMLDivElement>
	initialPosition?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
}) {
	const draggableRef = useRef<HTMLDivElement>(null)
	const [position, setPosition] = useState({ x: 0, y: 0 })
	const [isAnimating, setIsAnimating] = useState(false)

	useLayoutEffect(() => {
		if (parentRef?.current && draggableRef.current && initialPosition) {
			const parentWidth = parentRef.current.clientWidth
			const parentHeight = parentRef.current.clientHeight
			const elementWidth = draggableRef.current.offsetWidth
			const elementHeight = draggableRef.current.offsetHeight
			console.log(parentHeight - elementHeight - 16 * 2)
			let x = 0
			let y = 0

			switch (initialPosition) {
				case 'topRight':
					x = parentWidth - elementWidth - 16 * 2
					y = 0
					break
				case 'bottomLeft':
					x = 0
					y = parentHeight - elementHeight - 16 * 2
					break
				case 'bottomRight':
					x = parentWidth - elementWidth - 16 * 2
					y = parentHeight - elementHeight - 16 * 2
					break
				default:
					x = 0
					y = 0
			}

			setPosition({ x, y })
		}
	}, [parentRef, initialPosition])

	/**
	 * Maneja el evento de soltar el componente draggable y ajusta la posición a la esquina más cercana.
	 * @param {MouseEvent} e - El evento de mouse.
	 * @param {DraggableData} data - Datos proporcionados por el evento Draggable.
	 */

	const handleStop = (e: DraggableEvent, data: DraggableData) => {
		let nearestCorner

		if (parentRef && parentRef.current) {
			// Calculamos la esquina más cercana
			nearestCorner = getNearestCorner(
				data.x,
				data.y,
				parentRef.current.clientWidth - 16 * 2,
				parentRef.current.clientHeight - 16 * 2,
				data.node.clientWidth,
				data.node.clientHeight,
			)
		} else {
			nearestCorner = getNearestCorner(
				data.x,
				data.y,
				384 - 16 * 2,
				216 - 16 * 2,
				data.node.clientWidth,
				data.node.clientHeight,
			)
		}

		// Iniciamos la animación
		setIsAnimating(true)

		// Actualizamos la posición del componente para que se mueva a la esquina más cercana
		setPosition({ x: nearestCorner.x, y: nearestCorner.y })
		setTimeout(() => setIsAnimating(false), 300) // 300ms es la duración de la transición
	}

	return (
		<Draggable
			nodeRef={draggableRef}
			defaultClassName={`${isAnimating ? 'transition-all duration-300 ease-in-out absolute m-4' : ''}`}
			onStop={handleStop}
			position={position}
			bounds="parent"
		>
			<div ref={draggableRef} className="absolute">
				{children}
			</div>
		</Draggable>
	)
}

/**
 * Componente LocalVideo que muestra el estado de la cámara y micrófono de un usuario,
 * con la posibilidad de arrastrar y soltar la tarjeta que muestra el estado.
 * Permite pasar una referencia del padre para que el movimiento de la tarjeta se ajuste
 * a las dimensiones del elemento padre.
 * @param {boolean} cameraOff - Indica si la cámara está apagada.
 * @param {boolean} hideControls - Permite ocultar el ícono del mic dentro del video por si se prefiere manejar desde afuera del componente.
 * @param {'not working' | 'on' | 'off'} microphoneStatus - Estado del micrófono (no funcionando, encendido, apagado).
 * @param {string} name - Nombre del usuario que se muestra en la tarjeta.
 * @param {ReactNode} children - Elementos adicionales que se renderizarán dentro de la tarjeta (opcional).
 * @param {HTMLAttributes<HTMLDivElement>} props - Todas las propiedades adicionales que se aplicarán al div contenedor.
 * @param {React.Ref<HTMLDivElement>} ref - Referencia al contenedor div del video.
 * @param {React.RefObject<HTMLDivElement>} parentRef - Referencia al contenedor div del padre para ajustar los valores del SnappingDraggable.
 * @returns {JSX.Element} - El componente LocalVideo renderizado.
 */
export const LocalVideo = forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement> & {
		cameraOff: boolean
		microphoneStatus: 'not working' | 'on' | 'off'
		name: string
		children?: ReactNode
		parentRef?: React.RefObject<HTMLDivElement>
		hideControls?: boolean
		initialPosition?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
	}
>(
	(
		{
			cameraOff,
			name,
			parentRef,
			hideControls,
			children,
			initialPosition,
			...props
		},
		ref,
	) => {
		if (cameraOff) {
			return (
				<div
					{...props}
					className={cn(
						`p-4 absolute w-full h-full`,
						props.className,
					)}
				>
					<SnappingDraggable
						parentRef={parentRef}
						initialPosition={initialPosition}
					>
						<div className="w-36 h-20 border border-solid border-white bg-grey-800 flex flex-col justify-center items-center rounded-xl relative shadow-card">
							<div ref={ref}>{children}</div>
							<div className="p-4 rounded-full bg-tertiary-300 text-white w-9 h-9 flex justify-center items-center font-bold">
								{name}
							</div>
							{!hideControls && (
								<div className="absolute bottom-1 right-2.5 border-solid border-grey-50 border-[0.3px] bg-grey-600 rounded-full w-5 h-5 flex justify-center items-center">
									<Icon
										name="microphone"
										color="text-grey-50"
										size="size-3"
									/>
								</div>
							)}
						</div>
					</SnappingDraggable>
				</div>
			)
		}

		return (
			<div
				{...props}
				className={cn(`p-4 absolute w-full h-full`, props.className)}
			>
				<SnappingDraggable parentRef={parentRef}>
					<div className="w-36 h-20 border border-solid border-white bg-grey-800 flex flex-col justify-center items-center rounded-xl relative shadow-card">
						<div ref={ref}>{children}</div>
						{!hideControls && (
							<div className="absolute bottom-1 right-2.5 border-solid border-grey-50 border-[0.3px] bg-grey-600 rounded-full w-5 h-5 flex justify-center items-center">
								<Icon
									name="microphone"
									color="text-grey-50"
									size="size-3"
								/>
							</div>
						)}
					</div>
				</SnappingDraggable>
			</div>
		)
	},
)

LocalVideo.displayName = 'LocalVideo'
