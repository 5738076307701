const UploadFile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49" fill="none">
      <mask id="mask0_1408_28247" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="49" height="49">
        <rect x="0.5" y="0.5" width="48" height="48" fill="currentColor"/>
      </mask>
      <g mask="url(#mask0_1408_28247)">
        <path d="M23 37.269H26V28.4L29.7 32.1L31.8075 29.9615L24.5 22.654L17.1925 29.9615L19.331 32.069L23 28.4V37.269ZM13.1155 43.5C12.1052 43.5 11.25 43.15 10.55 42.45C9.85 41.75 9.5 40.8948 9.5 39.8845V9.1155C9.5 8.10517 9.85 7.25 10.55 6.55C11.25 5.85 12.1052 5.5 13.1155 5.5H29L39.5 16V39.8845C39.5 40.8948 39.15 41.75 38.45 42.45C37.75 43.15 36.8948 43.5 35.8845 43.5H13.1155ZM27.5 17.5V8.5H13.1155C12.9615 8.5 12.8205 8.56417 12.6925 8.6925C12.5642 8.8205 12.5 8.9615 12.5 9.1155V39.8845C12.5 40.0385 12.5642 40.1795 12.6925 40.3075C12.8205 40.4358 12.9615 40.5 13.1155 40.5H35.8845C36.0385 40.5 36.1795 40.4358 36.3075 40.3075C36.4358 40.1795 36.5 40.0385 36.5 39.8845V17.5H27.5Z" fill="currentColor"/>
      </g>
    </svg>
  )
}

export default UploadFile